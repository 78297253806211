import * as R from 'ramda';

const handleError = error => R.pipe(
  R.path(['response','data', 'data']),
  R.applySpec({
    errorHeader: R.prop('error'),
    message: R.prop('message'),
  }),
)(error);

export default handleError;
