import React from "react";
import b from './Headline.module.scss';

// Уровни заголовка
const HEADING_LEVEL = {
  h1: 1,
  h2: 2,
  h3: 3,
  h4: 4,
  h5: 5,
};

const Headline = (props) => {
  const {level} = props;
  return React.createElement(
    `h${HEADING_LEVEL[level]}`,
    {
      className: [
        typeof props.className !== 'undefined' && props.className,
        b.headline,
      ].join(' '),
    },
    props.children,
  );
};

export default Headline;

Headline.defaultProps = {
  level: 'h4'
};
