import * as R from 'ramda';
import {handleActions} from 'redux-actions';
import {combineReducers} from "redux";
import {
  RECEIVE_ORDER,
  REQUEST_ORDER,
  REQUEST_ORDER_FAILED,
  RESET_HIDDEN_FIELDS,
  RESET_ORDER,
  SET_FORM_FIELD,
  SET_ORDER_TYPE
} from "./actions";

const isFetching = handleActions(
  {
    [REQUEST_ORDER]: R.T,
    [RECEIVE_ORDER]: R.F,
    [REQUEST_ORDER_FAILED]: R.F
  },
  false,
);

const values = handleActions(
  {
    [RESET_HIDDEN_FIELDS]: (state) => ({
      ...state,
      postalcode: '',
      kladr: '',
      region: '',
      city: '',
      street: '',
      stead: '',
      house: '',
      block: '',
      floor: '',
      flat: '',
    }),
    [RESET_ORDER]: (state) => ({
      ...state,
      lastname: '',
      name: '',
      patronymic: '',
      email: '',
      phone: '',
      address: '',
      postalcode: '',
      kladr: '',
      region: '',
      city: '',
      street: '',
      stead: '',
      house: '',
      block: '',
      floor: '',
      flat: '',
      stadium: '',
      comment: ''
    }),
    [SET_FORM_FIELD]: (state, {payload}) => ({
      ...state,
      [payload.name]: payload.value
    })
  },
  {
    lastname: '',
    name: '',
    patronymic: '',
    email: '',
    phone: '',
    address: '',
    postalcode: '',
    kladr: '',
    region: '',
    city: '',
    street: '',
    stead: '',
    house: '',
    block: '',
    floor: '',
    flat: '',
    comment: ''
  },
);

const orderId = handleActions(
  {
    [RESET_ORDER]: () => null,
    [RECEIVE_ORDER]: (_, {payload}) => R.prop('orderId')(payload)
  },
  null,
);

const orderType = handleActions(
  {
    [SET_ORDER_TYPE]: (_, {payload}) => payload
  },
  'online',
)

const error = handleActions(
  {
    [RESET_ORDER]: () => null,
    [REQUEST_ORDER]: () => null,
    [REQUEST_ORDER_FAILED]: (_, {payload}) => payload
  },
  null,
);

export default combineReducers({
  isFetching,
  values,
  orderId,
  orderType,
  error
});
