import * as R from "ramda";
import React, {useState, useCallback, useRef, useEffect} from 'react';
import {connect} from "react-redux";
import {
  getEnteredCode, getEntry,
  getError,
  getIsFetching as getIsFetchingPromocode, removePromocode,
  requestPromocode
} from "../../state/promocode";
import {ReactComponent as IconRight} from './assets/arrow-right.svg';
import {ReactComponent as IconSuccess} from './assets/ic-success.svg';
import {ReactComponent as IconError} from './assets/ic-error.svg';
import {ReactComponent as IconLoading} from './assets/ic-loading.svg';
import b from './Promocode.module.scss'
import classNames from 'classnames/bind';

let cx = classNames.bind(b);

const InputForm = (props) => {
  const {expanded, code, isFetching, error, requestPromocode, removePromocode} = props;
  const inputRef = useRef();
  const [isEmpty, setIsEmpty] = useState(true);
  const [isFocus, setFocused] = useState(false);
  useEffect(() => {
    if (!R.isNil(code)) {
      setIsEmpty(false);
    }
  }, [code])
  const onChange = useCallback(
    (e) => {
      if (e.target.value === '') {
        setIsEmpty(true);
      } else {
        setIsEmpty(false);
      }
    },
    [],
  );
  const onClickSubmit = useCallback(() => {
    requestPromocode(inputRef.current?.value);
  }, [])
  const onClickDelete = useCallback(() => {
    inputRef.current.value = null;
    removePromocode();
  }, [])

  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);
  return (
    <div className={cx({
      inputGroup: true,
      empty: isEmpty,
      focused: isFocus,
      error: !R.isNil(error)
    })}>
      <input ref={inputRef} className={cx({
        input: true,
        expanded
      })}
             type="text"
             placeholder="Промокод"
             onFocus={onFocus}
             onBlur={onBlur}
             onChange={onChange}
             readOnly={!R.isNil(error)}
             maxLength={15}
             defaultValue={code}
      />
      {isFetching && (
        <span className={b.fetching}>
          <IconLoading />
        </span>
      )}
      {(!R.isNil(error) && !isFetching) && (
        <button onClick={onClickDelete} className={b.clearCode}>
          <IconError/>
        </button>
      )}
      {(R.isNil(error) && !isFetching) && (
        <button onClick={onClickSubmit} className={b.submit}>
          <IconRight/>
        </button>
      )}
    </div>
  );
}

const AppliedCode = (props) => {
  const {promocode, removePromocode} = props;
  const onClickDelete = useCallback(() => {
    removePromocode();
  }, [])
  return (
    <div className={cx({
      appliedGroup: true,
    })}>
      <div className={b.appliedCodeContainer}>
        <div className={b.appliedIcon}>
          <IconSuccess/>
        </div>
        <div className={b.appliedCode}>
          {promocode}
        </div>
      </div>
      <button onClick={onClickDelete} className={b.deleteCode}>
        <span className={b.deleteCodeText}>Удалить</span>
      </button>
    </div>
  );
}

const Promocode = (props) => {
  const {expanded = false, code, enteredCode, isFetching, error, requestPromocode, removePromocode} = props;
  return (
    <div className={cx(props.className, {
      promocode: true
    })}>
      {R.isNil(code)
        ? (<InputForm expanded={expanded}
                      code={enteredCode}
                      isFetching={isFetching}
                      error={error}
                      requestPromocode={requestPromocode}
                      removePromocode={removePromocode}
        />)
        : (<AppliedCode removePromocode={removePromocode} promocode={enteredCode}/>)
      }
      {(!R.isNil(error) || !R.isNil(code)) &&
        <div className={cx({
          label: true,
          isError: !R.isNil(error),
          isSuccess: false
        })}>
          {error?.message ? error?.message : 'Промокод успешно активирован'}
        </div>
      }
    </div>
  );
}

const mapDispatchToProps = {
  requestPromocode,
  removePromocode,
};

const mapStateToProps = R.applySpec({
  code: getEntry,
  enteredCode: getEnteredCode,
  error: getError,
  isFetching: getIsFetchingPromocode,
});

export default connect(mapStateToProps, mapDispatchToProps)(Promocode)
