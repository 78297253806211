import {combineReducers} from 'redux';
import good from './state/good';
import promocode from './state/promocode';
import order from './state/order';
import suggests from './state/suggests';

export default combineReducers({
  good,
  promocode,
  order,
  suggests
});
