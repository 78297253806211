import * as R from 'ramda';
import {ENDPOINTS} from '../../api';
import axios from "axios";

const getSuggests = list =>
  R.pipe(
    R.prop('data'),
    R.map(
      R.applySpec({
        value: R.prop('value'),
        kladr: R.prop('kladr'),
        postalcode: R.prop('postalcode'),
        region: R.prop('region'),
        city: R.prop('city'),
        street: R.prop('street'),
        stead: R.prop('stead'),
        house: R.prop('house'),
        block: R.prop('block'),
        floor: R.prop('floor'),
        flat: R.prop('flat'),
      }),
    ),
  )(list);

export const apiCall = (address) => {
  return axios.get(ENDPOINTS.suggests(address),
).then(response => {
    return getSuggests(response.data);
  })
    .catch(err => {
      throw err;
    });
}
