import React from "react";
import InputGroup from "../../../components/form/inputGroup/InputGroup";
import {useFormikContext} from "formik";

export const PersonalFields = () => {
  const {values, setFieldValue} = useFormikContext();
  return (
    <>
      <div className="form-row three-column">
        <div className="form-column">
          <InputGroup
            name="lastname"
            value={values.lastname}
            placeholder="Фамилия*"
            type='text'
          />
        </div>
        <div className="form-column">
          <InputGroup
            name='name'
            value={values.name}
            placeholder='Имя*'
            type='text'
          />
        </div>
        <div className="form-column">
          <InputGroup
            name='patronymic'
            value={values.patronymic}
            placeholder='Отчество'
            type='text'
          />
        </div>
      </div>
      <div className="form-row two-columns">
        <div className="form-column">
          <InputGroup
            name='email'
            value={values.email}
            placeholder='Почта*'
            type='email'
          />
        </div>
        <div className="form-column">
          <InputGroup
            name="phone"
            value={values.phone}
            placeholder="Телефон*"
            type="tel"
            onChange={e => {
              e.preventDefault();
              const {value} = e.target;
              const regex = /^\+?\d*$/;
              if (!value || regex.test(value.toString())) {
                setFieldValue("phone", value);
              }
            }}
          />
        </div>
      </div>
    </>
  );
}
