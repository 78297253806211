import {call, put, takeLatest, select} from 'redux-saga/effects';
import {receivePromocode, receivePromocodeFailed, REQUEST_PROMOCODE} from './actions';
import {apiCall} from "./manager";
import handleError from "../../utils/handleError";

const fetchPromocodeSaga = function* ({payload}) {
  try {
    const promocode = yield call(apiCall, payload);
    yield put(receivePromocode(promocode));
  }
  catch(error) {
    yield put(receivePromocodeFailed(handleError(error)))
  }
}

const promocodeSaga = function* () {
  yield takeLatest(REQUEST_PROMOCODE, fetchPromocodeSaga);
}

export default promocodeSaga;
