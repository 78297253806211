import * as R from 'ramda';
import {ENDPOINTS} from '../../api';
import axios from "axios";

const getGoods = list =>
  R.pipe(
    R.prop('data'),
    R.map(
      R.applySpec({
        id: R.prop('goodId'),
        name: R.prop('goodName'),
        price: R.prop('price'),
        vendorCode: R.prop('vendorCode'),
        photo: R.path(['photo', 'url']),
      }),
    ),
  )(list);

export const apiCall = () => {
  return axios.get(ENDPOINTS.goods,
  ).then(response => {
    return getGoods(response.data);
  })
    .catch(err => {
      throw err;
    });
}
