import React from 'react';
import b from './HeadlineSidebar.module.scss';

import classNames from 'classnames/bind';

let cx = classNames.bind(b);

const HeadlineSidebar = (props) => {
  return (
    <h2 className={cx(props.className, {
      headline: true,
    })}>{props.children}</h2>
  );
}

export default HeadlineSidebar;
