import React, {useEffect} from "react";
import b from './InputGroup.module.scss'
import {useField} from "formik";
import classNames from 'classnames/bind';
import {connect} from "react-redux";
import {setFormField} from "../../../state/order";
import * as R from "ramda";

let cx = classNames.bind(b);

const InputGroup = ({setFormField, placeholder = '', type='text', ...props}) => {
  const [field, meta] = useField(props);
  useEffect(() => {
    setFormField({name: field.name, value: field.value || ''})
  }, [field.value])
  return (
    <>
      <div className={cx(props.className, {
        inputGroup: true,
        up: !R.isEmpty(field.value),
        errors: meta.touched && meta.error
      })}>
        <input {...field} {...props} type={type} className={cx({input: true})} />
        {placeholder && (
          <span className={cx({placeholder: true})}>{placeholder}</span>
        )}
      </div>
      {meta.touched && meta.error ? (
        <div className={b.error}>{meta.error}</div>
      ) : null}
    </>
  );
}

const mapDispatchToProps = {
  setFormField,
}

export default connect(null, mapDispatchToProps)(InputGroup);
