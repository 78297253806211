import React from 'react';
import {useMediaQuery} from 'react-responsive'
import b from './Onboard.module.scss';
import Button from "../../components/button/Button";
import pattern from './assets/eight-pattern.png';
import pattern2x from './assets/eight-pattern@2x.png';
import ball from './assets/ball.png';
import ball2x from './assets/ball@2x.png';
import {Link} from "react-router-dom";

export const Onboard = () => {
  const isMobile = useMediaQuery({maxWidth: 639});
  return (
    <div className="row">
      <div className={`${b.withPattern} large-12 columns`}>
        <div className="row">
          <div className="small-12 large-8 column">
            <h1 className={`${b.headline} small-m-b-25 m-b-60`}>
              <span className={b.headlineAccent}>Меняй баллы</span>
              на мерч чемпионата
            </h1>
            <div className={`${b.buttonLink}`}>
              <Button to="/goods" size="large" expanded={isMobile}>Перейти к выбору</Button>
            </div>
          </div>
        </div>
        <div className={b.patternContainer}>
          <Link draggable={false} to="/goods">
            <img
              className={b.ball}
               src={ball}
               srcSet={`${ball2x} 2x`}
               width={540}
               height={374}
               draggable={false}
               alt=""
            />
            <img
              className={b.pattern}
              src={pattern}
              srcSet={`${pattern2x} 2x`}
              width={460}
              height={1002}
              draggable="false"
              alt=""
            />
          </Link>
        </div>
      </div>
      <div className={`${b.featuresContainer} small-12 large-12 columns`}>
        <div className={b.features}>
          <div className={b.feature}>
            <div className={b.featureContent}>
              <div className={b.featureNumber}><span>1</span></div>
              <div className={b.featureText}>
                <span className={b.featureTextAccent}>Введи промокод</span> и активируй соответствующий товар
              </div>
            </div>
          </div>
          <div className={b.feature}>
            <div className={b.featureContent}>
              <div className={b.featureNumber}><span>2</span></div>
              <div className={b.featureText}>
                <span className={b.featureTextAccent}>Подтверди товар</span> или введи другой промокод
              </div>
            </div>
          </div>
          <div className={b.feature}>
            <div className={b.featureContent}>
              <div className={b.featureNumber}><span>3</span></div>
              <div className={b.featureText}>
                <span className={b.featureTextAccent}>Оформи заказ</span> оставив контактные данные
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Onboard;
