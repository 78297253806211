import {call, put, takeLatest} from 'redux-saga/effects';
import {apiCall} from "./manager";
import {emptyGoods, receiveGoods, REQUEST_GOODS} from './actions';

const fetchGoodsSaga = function* () {
  yield put(emptyGoods());
  const goods = yield call(apiCall);
  yield put(receiveGoods(goods));
};

const goodsSaga = function* () {
  yield takeLatest(REQUEST_GOODS, fetchGoodsSaga);
};

export default goodsSaga;
