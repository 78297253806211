import * as R from "ramda";
import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {useMediaQuery} from 'react-responsive'
import Headline from "../../components/headline/Headline";
import Button from "../../components/button/Button";
import Pager from "../../components/pager/Pager";
import Promocode from "../../components/promocode/Promocode";
import HeadlineSidebar from "../../components/sidebar/HeadlineSidebar";
import ParagraphSidebar from "../../components/sidebar/ParagraphSidebar";
import GoodSelector from "../../components/goodSelector/GoodSelector";
import b from './SelectGood.module.scss';
import {useNavigate} from "react-router-dom";
import {
  requestGoods,
} from "../../state/good";
import {getEntry as getPromocode} from "../../state/promocode";

const SelectGood = ({promocode, requestGoods}) => {
  const navigate = useNavigate();
  useEffect(() => {
    requestGoods();
  }, [requestGoods, navigate]);
  const isMobile = useMediaQuery({maxWidth: 639});
  return (
    <div className="row small-collapse large-uncollapse">
      <div className={`${b.sideBarContainer} small-12 large-3 column`}>
        <div className={`${b.sideBar} small-p-l-20 small-p-r-20 medium-p-l-20 medium-p-r-20`}>
          <div className={b.promocodeContainer}>
            <div className={b.promocodeInner}>
              <HeadlineSidebar className={`${b.headlineSidebar} small-m-b-15 medium-m-b-0 `}>Введи
                свой <span>промокод</span></HeadlineSidebar>
              <ParagraphSidebar className={`${b.paragraphSidebar} m-b-15 medium-m-b-20 medium-m-t-10`}>После введения
                промокода, полученного на сайте Winline, станет доступен соответствующий товар:</ParagraphSidebar>
              <div className={b.promocodeInputContainer}>
                <Promocode expanded={true}/>
              </div>
            </div>
          </div>
          <Pager className={`${b.sidebarPager} medium-m-t-25`} page={1}/>
        </div>
      </div>
      <div className="small-12 large-8 large-offset-1 column small-m-b-40 medium-m-b-40">
        <div
          className='position-relative m-t-40 m-b-25 small-m-t-35 medium-m-t-35 small-m-b-20 small-p-l-20 small-p-r-20 medium-p-l-20 medium-p-r-20'>
          <Headline level="h3" className="inline-block">Список товаров</Headline>
          <span className={b.headlineLabel}>Товар будет доступен после ввода промокода</span>
        </div>
        <GoodSelector/>
        <div className={b.buttonContainer}>
          <Button
            to="/order"
            expanded={isMobile}
            disabled={R.isNil(promocode)}
            label={true}
            labelText="Размер товара указывается при оформлении"
          >
            Подтвердить заказ
          </Button>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  requestGoods,
};

const mapStateToProps = R.applySpec({
  promocode: getPromocode,
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectGood);
