import React from 'react';
import b from './Pager.module.scss';

import classNames from 'classnames/bind';

let cx = classNames.bind(b);

const Pager = (props) => {
  const {page = 1} = props;
  return (
    <div className={cx(props.className, {
      pager: true,
    })}>
      <div className={b.pagerInner}>
        <span className={cx({
          page: true,
          active: page === 1,
        })}>1</span>
        <span  className={cx({
          page: true,
          active: page === 2,
        })}>2</span>
      </div>
    </div>
  );
}

export default Pager;
