import React from 'react';
import b from './NavButton.module.scss'
import classNames from 'classnames/bind';
import {Link} from "react-router-dom";
import {ReactComponent as IconChevronLeft} from './assets/ic-chevron-left.svg';

let cx = classNames.bind(b);

const NavButton = (props) => {
  const {to, disabled = false} = props;
  return (
    <div className={cx(props.className, {
      linkContainer: true,
    })}>
      <Link draggable={false} to={to} className={cx({
        btn: true,
        disabled,
      })}>
        <span className={b.icon}>
          <IconChevronLeft/>
        </span>
        <span className={b.text}>
          {props.children}
        </span>
      </Link>
    </div>
  )
}

export default NavButton;
