import * as R from "ramda";
import {connect} from "react-redux";
import React from 'react';
import b from './Layout.module.scss';
import {Link, Outlet} from "react-router-dom";
import {useLocation} from 'react-router-dom';
import {ReactComponent as Logo} from './assets/winline-logo.svg';
import {ReactComponent as LogoSmall} from './assets/winline-logo-small.svg';
import {ReactComponent as Rhombus} from './assets/rhombus.svg';
import HeaderButton from "../../components/headerButton/HeaderButton";
import Modal from "../../components/modal/Modal";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classNames from 'classnames/bind';
import {getOrderId, getOrderName} from "../../state/order";

let cx = classNames.bind(b);

const Layout = ({orderId}) => {
  const {pathname} = useLocation();
  let page = 'onboard';
  const path = pathname.replace(/[^a-zA-Z]+/g, '');
  if (path.length > 0) {
    page = path;
  }

  return (
    <div className={cx({
      [page]: true,
      generalPage: true,
      showModal: orderId
    })}>
      <div className={b.navigationContainer}>
        <div className={`${b.navigation} row position-relative`}>
          <div className="small-6 large-4 column">
            <Link className={b.logoLink} draggable={false} to='/'>
              <div className={b.logoContainer}>
                <span className={`${b.logo} show-for-small-only`}>
                  <LogoSmall/>
                </span>
                <span className={`${b.logo} show-for-medium`}>
                  <Logo/>
                </span>
                <span className={b.rhombus}>
                  <Rhombus/>
                </span>
                <span className={b.logoText}>
                  QATAR'22
                </span>
              </div>
            </Link>
          </div>
          <div className="large-offset-5 large-3 column">
            <span className={b.headerButton}>
              <HeaderButton href="https://winline.ru"><span
                className="show-for-medium">На сайт</span> winline.ru</HeaderButton>
            </span>
          </div>
        </div>
      </div>
      <main className={`page ${page}`}>
        <Outlet/>
      </main>
      {orderId && (
        <Modal/>
      )}
      <ToastContainer/>
    </div>
  );
}

const mapStateToProps = R.applySpec({
  orderId: getOrderId,
  name: getOrderName,
});

export default connect(mapStateToProps)(Layout);
