import * as R from "ramda";
import {connect} from "react-redux";
import React, {useCallback, useEffect, useState} from "react";
import {useField, useFormikContext} from "formik";
import b from './InputSuggest.module.scss'
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import Autosuggest from "react-autosuggest";
import {
  clearSuggest,
  getSuggests,
  requestSuggest
} from "../../../state/suggests";
import classNames from 'classnames/bind';
import {setFormField} from "../../../state/order";

let cx = classNames.bind(b);

const InputSuggest = (props) => {
  const {suggests, onBlur, requestSuggest, clearSuggest, setFormField} = props;
  const [field, meta, helpers] = useField(props);
  const {setFieldValue} = useFormikContext()
  const {setValue} = helpers;
  const [suggestions, setSuggestion] = useState([]);
  const {placeholder = '', type = 'text'} = props;
  const inputProps = {
    ...field,
    type,
    onBlur,
    className: cx({input: true})
  };

  useEffect(() => {
    if (suggests.length > 0) {
      clearSuggest();
      setSuggestion(suggests);
    }
  }, [suggests])

  const setAdditionalValue = (key, value) => {
    setFieldValue(key, value);
    setFormField({name: key, value: value});
  };

  const getSuggestionValue = suggestion => {
    setValue(suggestion.value);
    setFormField({name: field.name, value: suggestion.value});
    setAdditionalValue('postalcode', suggestion.postalcode);
    setAdditionalValue('kladr', suggestion.kladr);
    setAdditionalValue('region', suggestion.region);
    setAdditionalValue('city', suggestion.city);
    setAdditionalValue('street', suggestion.street);
    setAdditionalValue('stead', suggestion.stead);
    setAdditionalValue('house', suggestion.house);
    setAdditionalValue('block', suggestion.block);
    setAdditionalValue('floor', suggestion.floor);
    setAdditionalValue('flat', suggestion.flat);
    return suggestion.value
  };
  const renderSuggestion = (suggestion, {query}) => {
    const matches = match(suggestion.value, query);
    const parts = parse(suggestion.value, matches);
    return (
      <span>
      {parts.map((part, index) => {
        const className = part.highlight ? 'react-autosuggest__suggestion-match' : null;
        return (
          <b className={className} key={index}>
            {part.text}
          </b>
        );
      })}
    </span>
    );
  };
  const onSuggestionsFetchRequested = useCallback(
    ({value}) => {
      const escapedValue = escapeRegexCharacters(value.trim());
      if (escapedValue === '') {
        return [];
      }
      if (value.length > 3) {
        requestSuggest(value);
      }
    },
    [requestSuggest],
  );
  const onSuggestionsClearRequested = useCallback(
    () => {
      setSuggestion([]);
    },
    [],
  );

  return (
    <>
      <div className={cx(props.className, {
        inputGroup: true,
        up: !R.isEmpty(field.value),
        errors: meta.touched && meta.error
      })}>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          highlightFirstSuggestion={true}
          inputProps={inputProps}
        />
        {placeholder && (
          <span className={cx({placeholder: true})}>{placeholder}</span>
        )}
      </div>
      {meta.touched && meta.error ? (
        <div className={b.error}>{meta.error}</div>
      ) : null}
    </>
  );
}

const escapeRegexCharacters = (str) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

const mapDispatchToProps = {
  requestSuggest,
  clearSuggest,
  setFormField,
}
const mapStateToProps = R.applySpec({
  suggests: getSuggests,
});

export default connect(mapStateToProps, mapDispatchToProps)(InputSuggest);
