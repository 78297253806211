import React from "react";
import InputGroup from "../../../components/form/inputGroup/InputGroup";
import {useFormikContext} from "formik";

export const CommentField = () => {
  const {values} = useFormikContext();
  return (
    <div className="form-row one-column">
      <div className="form-column">
        <InputGroup
          name="comment"
          value={values.comment}
          placeholder="Комментарий"
          type="text"
        />
      </div>
    </div>
  );
}
