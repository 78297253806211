import * as R from 'ramda';
import {getGoods} from "../good";
import {getSelectedGoodId} from "../promocode";
import {getOrderState} from "../../module-state-selectors";

export const getOrderGood = R.converge(
  (entities, selectedId) => R.find(R.propEq('id', selectedId), entities),
  [getGoods, getSelectedGoodId],
);
export const getIsFetching = R.pipe(getOrderState, R.prop('isFetching'));
export const getOrderId = R.pipe(getOrderState, R.prop('orderId'));
export const getOrderValues = R.pipe(getOrderState, R.prop('values'))
export const getOrderName = R.pipe(getOrderState, R.path(['values', 'name']))
export const getOrderType = R.pipe(getOrderState, R.prop('orderType'))
