import * as Yup from "yup";

const phoneRegExp = /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/gm

export const online = Yup.object({
  lastname: Yup.string().required('Обязательное поле'),
  name: Yup.string().required('Обязательное поле'),
  email: Yup.string().email('Неверный адрес электронной почты').required('Обязательное поле'),
  phone: Yup.string().matches(phoneRegExp, 'Правильный формат номера телефона +78001234567').required('Обязательное поле'),
  address: Yup.string().required('Обязательное поле'),
})

export const stadium = Yup.object({
  lastname: Yup.string().required('Обязательное поле'),
  name: Yup.string().required('Обязательное поле'),
  email: Yup.string().email('Неверный адрес электронной почты').required('Обязательное поле'),
  phone: Yup.string().matches(phoneRegExp, 'Правильный формат номера телефона +78001234567').required('Обязательное поле'),
})
