import React from 'react';
import b from './ParagraphSidebar.module.scss';

import classNames from 'classnames/bind';

let cx = classNames.bind(b);

const ParagraphSidebar = (props) => {
  return (
    <p className={cx(props.className, {
      paragraph: true,
    })}>{props.children}</p>
  );
}

export default ParagraphSidebar;
