import * as R from "ramda";
import React from 'react';
import {connect} from "react-redux";
import b from './Order.module.scss'
import {useMediaQuery} from 'react-responsive'
import {Formik} from 'formik';
import Headline from "../../components/headline/Headline";
import Button from "../../components/button/Button";
import Pager from "../../components/pager/Pager";
import HeadlineSidebar from "../../components/sidebar/HeadlineSidebar";
import ParagraphSidebar from "../../components/sidebar/ParagraphSidebar";
import NavButton from "../../components/navButton/NavButton";
import SidebarOrder from "../../components/sidebarOrder/SidebarOrder";
import {
  getIsFetching as getIsOrderFetching,
  getOrderGood, getOrderType,
  getOrderValues,
  requestOrder,
  resetHiddenFields
} from "../../state/order";
import {useLocation, Navigate} from "react-router-dom";
import {OnlineDelivery} from "./delivery-type/OnlineDelivery";
import {PersonalFields} from "./personal-data/PersonalFields";
import {CommentField} from "./comment/CommentField";
import {online} from "./validation-scheme/schemes";

const Order = ({good, type, formValues, isFetching, requestOrder, resetHiddenFields}) => {
  let {state} = useLocation();
  const isMobile = useMediaQuery({maxWidth: 639});
  if (R.isNil(state?.prevRoute)) {
    return (<Navigate to="/" replace={true}/>)
  } else if (!R.isNil(state?.prevRoute) && R.isNil(good)) {
    return (<Navigate to={state?.prevRoute.pathname || '/'} replace={true}/>)
  } else {
    const scheme = {online};
    return (
      <div className="row small-collapse">
        <div className={`${b.sideBarContainer} small-12 large-4 column small-m-b-35`}>
          <div className={`${b.sideBar} small-p-l-20 small-p-r-20`}>
            <div className={b.orderContent}>
              <HeadlineSidebar className={`${b.headlineSidebar}`}>Оформление
                заказа</HeadlineSidebar>
              <ParagraphSidebar className="m-b-30">Заполни форму, и менеджеры свяжутся с тобой по указанным контактным
                данным для подтверждения заказа.</ParagraphSidebar>
            </div>
            <div className={b.sidebarOrder}>
              <SidebarOrder/>
            </div>
            <Pager className={`${b.sidebarPager} medium-m-t-25`} page={2}/>
          </div>
        </div>
        <div className={`${b.formWrapper} small-12 large-8 column small-m-b-40 medium-m-b-40`}>
            <Formik
              initialValues={formValues}
              validationSchema={scheme[type]}
              validate={(values) => {
                const errors = {};
                if (type === 'online') {
                  if (values.address !== formValues.address) {
                    errors.address = 'Адрес необходимо выбрать из списка';
                  } else if (R.isEmpty(values.kladr) || R.isNil(values.kladr)) {
                    errors.address = 'К сожалению, по указанному адресу доставка транспортной компанией невозможна. Попробуйте указать другой адрес.';
                  }
                }
                return errors;
              }}
              onSubmit={(values, {setSubmitting}) => {
                if (type === 'online') {
                  if (values.address !== formValues.address) {
                    resetHiddenFields();
                  } else {
                    requestOrder(values);
                  }
                } else {
                  requestOrder(values);
                }
                setSubmitting(false);
              }}
            >
              {({
                  handleSubmit,
                  isSubmitting,
                }) => (
                <form onSubmit={handleSubmit}>
                  <div className={b.formContainer}>
                    <Headline className="m-b-10">Личные данные</Headline>
                    <PersonalFields/>
                    <Headline className="m-b-10 m-t-25">Доставка</Headline>
                    <OnlineDelivery className="m-b-10"/>
                    <CommentField/>
                  </div>
                  <div className={b.buttonContainer}>
                    <Button
                      type="submit"
                      expanded={isMobile}
                      disabled={isSubmitting || isFetching}
                      label={true}
                      labelText="Заполни обязательные поля">
                      Оформить заказ
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  requestOrder,
  resetHiddenFields,
};

const mapStateToProps = R.applySpec({
  good: getOrderGood,
  formValues: getOrderValues,
  type: getOrderType,
  isFetching: getIsOrderFetching,
});

export default connect(mapStateToProps, mapDispatchToProps)(Order);


