import * as R from "ramda";
import React from 'react';
import {connect} from "react-redux";
import b from './SidebarOrder.module.scss'
import {getOrderGood} from "../../state/order";
import {Link, useLocation} from "react-router-dom";
const SidebarOrder = ({order}) => {
  let {state} = useLocation();
  const {name, photo, price} = order;
  return (
    <div className={b.orderContainer}>
      <div className={b.orderHeadline}>
        Твой заказ:
        <div className={b.changeOrder}>
          <Link draggable={false} to={state?.prevRoute.pathname || '/goods'}>
            Изменить
          </Link>
        </div>
      </div>
      <div className={b.order}>
        <div className={b.orderPhoto}>
          <img
            src={photo}
            width='52' height='57' alt={name}/>
        </div>
        <div className={b.orderInfo}>
          <div className={b.orderTitle}>{name}</div>
          <div className={b.orderPrice}>{price}</div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = R.applySpec({
  order: getOrderGood
});

export default connect(mapStateToProps)(SidebarOrder)
