import React from 'react';
import ReactDOM from "react-dom/client";
import {Provider} from 'react-redux';
import {
  BrowserRouter,
  Navigate,
  Routes,
  Route,
} from "react-router-dom";
import './index.scss';
import Onboard from "./routes/onboarding/Onboard";
import Layout from "./routes/layout/Layout";
import SelectGood from "./routes/good/SelectGood";
import Order from "./routes/order/Order";
import Magic from "./routes/magic/Magic";
import configureStore from "./configureStore";

const store = configureStore();

const root = ReactDOM.createRoot(
  document.getElementById("root")
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout/>}>
            <Route index element={<Onboard/>}/>
            <Route path="goods" element={<SelectGood />} />
            <Route path="order" element={<Order/>}/>
            <Route path="magic" element={<Magic/>}/>
            <Route
              path="*"
              element={<Navigate to="/" replace />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
