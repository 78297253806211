import * as R from 'ramda';
import {ENDPOINTS} from '../../api';
import axios from "axios";

const getOrderId = order => R.pipe(
  R.prop('data'),
  R.applySpec({
    orderId: R.prop('orderId'),
  }),
)(order);

export const apiCall = (payload) => {
  return axios.post(ENDPOINTS.createOrder, payload
  ).then(response => {
    return getOrderId(response.data);
  })
    .catch(err => {
      throw err;
    });
}
