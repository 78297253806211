import {call, put, takeLatest, select} from 'redux-saga/effects';
import {apiCall} from "./manager";
import handleError from "../../utils/handleError";
import {receiveOrder, receiveOrderFailed, REQUEST_ORDER} from "./actions";
import {getEnteredCode, getEntry as getPromocodeInfo} from "../promocode";
import {toast} from "react-toastify";
import {getOrderType} from "./selectors";

const fetchOrderSaga = function* ({payload}) {
  try {
    const {id, goodId} = yield select(getPromocodeInfo);
    const promocode = yield select(getEnteredCode);
    const orderType = yield select(getOrderType);
    const {lastname, name, patronymic, email, phone, comment} = payload;
    const defaultDataOrder = {
      goodId,
      promocodeId: id,
      promocode,
      type: orderType,
      comment,
      customer: {
        lastname,
        name,
        patronymic,
        email,
        phone
      }
    };
    let deliveryData;
    if (orderType === 'stadium') {
      const {stadium} = payload;
      deliveryData = {
        delivery: {
          stadium,
        }
      }
    } else {
      const {address, postalcode, kladr, region, city, street, stead, house, block, floor, flat} = payload;
      deliveryData = {
        delivery: {
          address,
          postalcode,
          kladr,
          region,
          city,
          street,
          stead,
          house,
          block,
          floor,
          flat,
        }
      }
    }
    const orderId = yield call(apiCall, {...defaultDataOrder, ...deliveryData});
    yield put(receiveOrder(orderId));
  } catch (error) {
    const handledError = handleError(error);
    yield put(receiveOrderFailed(handledError))
    yield toast.error(handledError.message);
  }
}

const orderSaga = function* () {
  yield takeLatest(REQUEST_ORDER, fetchOrderSaga);
}

export default orderSaga;
