import React, {useEffect, useState} from 'react';
import {FreeMode, Mousewheel, Navigation} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/scss";
import "swiper/scss/free-mode";
import b from './GoodSelector.module.scss';
import classNames from 'classnames/bind';
import ContentLoader from "react-content-loader";
import {connect} from "react-redux";
import * as R from "ramda";
import {getEntry as getPromocode} from "../../state/promocode";
import {getGoods, getIsFetching as getIsFetchingGoods} from "../../state/good";

let cx = classNames.bind(b);

const GoodSelector = (props) => {
  const {goods, isFetching, code} = props;
  const placeholder = [1, 2, 3];
  const [swiper, setSwiper] = useState(null);
  useEffect(() => {
    if (goods.length !== 0 && !R.isNil(code) && swiper?.initialized) {
      const index = R.findIndex(R.propEq('id', code.goodId))(goods);
      swiper.slideTo(index, 200)
    } else if (goods.length !== 0 && R.isNil(code) && swiper?.initialized) {
      swiper.slideTo(0, 200)
    }
  }, [goods, code, swiper]);
  return (
    <div className={cx(props.className, {
      tabScroller: true,
    })}>
      <div className={b.tabScrollerInner}>
        <Swiper
          onSwiper={setSwiper}
          slidesPerView="auto"
          spaceBetween={24}
          threshold={10}
          freeMode={true}
          navigation
          mousewheel={true}
          modules={[FreeMode, Navigation, Mousewheel]}
          className={b.tabList}>
          {isFetching ? (
            placeholder.map((id, index) => (
              <SwiperSlide key={id} virtualIndex={index} className={cx({
                goodTab: true,
              })}>
                <div className={b.goodTabInner}>
                  {<Skeleton/>}
                </div>
              </SwiperSlide>
            ))
          ) : (
            goods.map(({id, name, price, vendorCode, photo}, index) => (
              <SwiperSlide key={id} virtualIndex={index} className={cx({
                goodTab: true,
                selected: !R.isNil(code) && code?.goodId === id,
                unSelected: !R.isNil(code) && code?.goodId !== id,
              })}>
                <div className={b.goodTabInner}>
                  <div className={b.goodTabInner}>
                    <div className={`${b.goodTabPhoto}`}>
                      <img src={photo} width="132" height="132" alt={name}/>
                    </div>
                    <div className={b.namePriceContainer}>
                      <div className={b.goodName}>{name}</div>
                      <div className={b.goodPrice}>{price}</div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))
          )}
        </Swiper>
      </div>
    </div>
  );
}

const Skeleton = (props) => (
  <ContentLoader
    speed={2}
    width={238}
    height={305}
    viewBox="0 0 238 305"
    backgroundColor="#790F3214"
    foregroundColor="#50002728"
    {...props}
  >
    <path d="M 0 15 C 0 6.716 6.716 0 15 0 h 208 c 8.284 0 15 6.716 15 15 v 250 H 0 V 15 z"/>
    <rect x="16" y="287" rx="7" ry="7" width="122" height="14"/>
    <rect x="182" y="282" rx="10" ry="10" width="40" height="23"/>
  </ContentLoader>
)

const mapStateToProps = R.applySpec({
  code: getPromocode,
  goods: getGoods,
  isFetching: getIsFetchingGoods
});

export default connect(mapStateToProps)(GoodSelector);
