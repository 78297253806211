import React from "react";
import InputSuggest from "../../../components/form/inputSuggest/InputSuggest";
import {useFormikContext} from "formik";

export const OnlineDelivery = (props) => {
  const {values} = useFormikContext();
  return (
    <div className={`form-row one-column ${props.className}`}>
      <div className="form-column">
        <InputSuggest name="address"
          value={values.address}
          placeholder="Город, улица, дом, квартира"
          type="text"
        />
        <input type="hidden" value={values.postalcode} name="postalcode"/>
        <input type="hidden" value={values.kladr} name="kladr"/>
        <input type="hidden" value={values.region} name="region"/>
        <input type="hidden" value={values.city} name="city"/>
        <input type="hidden" value={values.street} name="street"/>
        <input type="hidden" value={values.stead} name="stead"/>
        <input type="hidden" value={values.house} name="house"/>
        <input type="hidden" value={values.block} name="block"/>
        <input type="hidden" value={values.floor} name="floor"/>
        <input type="hidden" value={values.flat} name="flat"/>
      </div>
    </div>);
}
